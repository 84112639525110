<template>
  <main class="view --login" :data-version="version">
    <div class="language-selector">
      <LocaleChanger :simple="true" />
    </div>
    <img :src="company_logo_url" />
    <div class="login-wrapper col-4 align-center">
      <form @submit.prevent="Login">
        <label class="login-wrapper__label" for="username">
          <span class="label__span">
            {{ $t('Username') }}
          </span>
          <input class="label__input" type="text" id="email" @input="FloatSpan" name="username" :placeholder="$t('Username')">
        </label>
        <label class="login-wrapper__label" for="password">
          <span class="label__span">
            {{ $t('Password') }}
          </span>
          <input class="label__input" type="password" id="password" @input="FloatSpan" name="password" :placeholder="$t('Password')">
        </label>
        <input class="submit" type="submit" :value="$t('Log in')">
      </form>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <Messages />
  </main>
</template>

<script>
  import LocaleChanger from '@/components/snippets/LocaleChanger';
  import Messages from '@/components/snippets/Messages';
  import { Config } from '@/helpers/Config';
  import { BPA }  from '@/helpers/BPA';
  import conf from '../../public/conf.json';

  export default {
    name: 'Login',
    mixins: [Config, BPA],
    components: {
      LocaleChanger,
      Messages
    },
    data() {
      return {
        loading: false,
        version: 'v ' + conf.version
      }
    },
    created() {
      let filter = this.GetValue('filter');
      if (!filter) {
        filter = {
          a4: ['not_label'],
          label: ['brother', 'dymo', 'zdesigner', 'zebra']
        };
      } else {
        if (!filter.a4) {
          filter.a4 = ['not_label'];
        }
        if (!filter.label) {
          filter.label = ['brother', 'dymo', 'zdesigner', 'zebra'];
        }
      }
      this.SetValue(filter, 'filter');
    },
    async mounted() {
      BPA.api.ValidateCompany();
    },
    computed: {
      company_logo_url() {
        return BPA.util.GetCompanyLogo(BPA.util.GetCompany()) + '?' + new Date().getTime();
      }
    },
    methods: {
      GetValue(type) {
        return BPA.printer.fetch(type);
      },
      SetValue(value = '', type = '') {
        BPA.printer.handler({key: type, set: value});
      },
      FloatSpan(e) {
        e.target.previousSibling.classList[e.target.value ? 'add' : 'remove']('float');
      },
      async Login() {
        const request = {};
        const show_error_message = (message) => {
          this.$eventHub.$emit('ShowMessages', {
            message: message || 'Incorrect username or password',
            type: 'error',
            hide: 2000
          });
        }
        ['email', 'password'].forEach(prop => {
          request[prop] = document.getElementById(prop).value;
          if (!request[prop]) delete request[prop];
        });
        if (Object.keys(request).length < 2) {
          return show_error_message();
        }
        this.loading = true;
        await BPA.session.Login(request).then(response => {
          this.loading = false;
          BPA.api.response({response, 
            return: () => {
              this.$router.push('/').catch(e => e);
            },
            error: (message) => {
              let defined = message != 'undefined';
              show_error_message(defined && !/444/.test(message) && message);
            }
          });
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/style/variables/icons';

  .language-selector {
    top: 10px;
    left: 20px;
    position: absolute;
  }

  main.view {
    position: relative;

    &:before {
      top: 0px;
      right: 20px;
      content: '';
      width: 80px;
      height: 60px;
      position: fixed;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: $logo_128x128;
      //background-image: url(../assets/images/packship_logo_128x128.png);
    }

    &:after {
      right: 20px;
      bottom: 10px;
      text-align: right;
      color: #C2C8CE;
      font-weight: 600;
      position: fixed;
      text-align: center;
      letter-spacing: 0.5px;
      content: attr(data-version);
    }
  }
</style>